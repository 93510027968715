import React, { useEffect, useState } from 'react';
import './App.css';
import axios from 'axios';

const App = ({}) => {

  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [url, setUrl] = useState("");

  useEffect(() => {
    console.log(window.location.pathname);
    const hash = window.location.pathname.replace('/', '');
    axios.get(`https://aurora.bmnt.io/shortening/${hash}`).then(response => {
      setLoaded(true);
      setUrl(response.data.url);
      window.location = response.data.url;
      
      console.log(response.data.url);
    }).catch(error => {
      setError(true);
      console.log(error);
    } );
  }, [])

  return (
    <div>
      <img width="150px" src="bmnt_regular_logo_360.png" />
      <br/> <br/>
      {!error && !loaded && "Looking up link"}
      {loaded && <p>Automatically redirecting, <a href={url}>click here to open link manually.</a></p>}
      {error && "Page not found"  }
    </div>
  );
}

export default App;
